@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');


@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}


.formContainer {

  background-image: url("./img/bg.png");
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .copyright {
    background-color: #F8EDE3;
    margin-top: 2%;
    padding: 15px 30px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 50px;


    p {
      font-size: 13px;
      color: 85586F;
      font-weight: 500;

      a {
        color: #85586F;
        text-decoration: none;
      }


    }
  }

  .formWrapper {
    background-color: #F8EDE3;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

    padding: 45px 60px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    @include mobile {
      padding: 20px 30px;
    }

    .logo {
      color: #000000;
      font-weight: 600;
      font-size: 30px;

      @include mobile {
        font-size: 25px;
      }
    }

    .title {
      color: #3c3c3c;
      font-size: 17px;
      margin-bottom: 20px;
    }

    form {

      display: flex;
      flex-direction: column;
      gap: 15px;

      input {
        padding: 15px;
        border: none;
        width: 250px;
        border-bottom: 1px solid #000000;
        transition: 0.2s ease-in-out;
        outline: none;
        background-color: transparent;

        &::placeholder {
          color: rgb(79, 79, 79);
          font-weight: 400;
        }


      }

      input:focus {
        border-bottom: 3px solid #000000;
        transition: 0.2s ease-in-out;
      }

      button {
        margin-top: 20px;
        background-color: #85586F;
        color: #F8EDE3;
        padding: 10px;
        font-weight: bold;
        border: none;
        border-radius: 3px;

        cursor: pointer;
        transition: 0.2s ease-in-out;
      }

      button:hover {
        margin-top: 20px;
        background-color: #5c3248;
        color: white;
        padding: 10px;
        font-weight: bold;
        border: none;
        cursor: pointer;
        transition: 0.2s ease-in-out;

      }

      label {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #393939;
        font-size: 13px;
        cursor: pointer;
        margin-top: 5px;
        font-weight: 600;

        img {
          width: 27px;
        }
      }
    }

    p {
      color: #000000;
      font-size: 14px;
      margin-top: 12px;
      font-weight: 600;


    }


    .error {
      color: #de0000;
      font-size: 14px;
      margin-top: -3%;
    }
  }
}

// HOME ELEMENTS

.home {
  background-image: url("./img/bg.png");
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {

    width: 80%;
    height: 80%;
    border-radius: 15px;
    display: flex;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

    @include tablet {
      width: 100%;

    }

    .sidebar {
      flex: 0.73;
      background-color: #DFD3C3;
      position: relative;
      overflow: scroll;


      .navbar {
        border-bottom: 2px solid #D0B8A8;

        display: flex;
        align-items: center;
        background-color: #DFD3C3;
        height: 50px;
        padding: 10px;
        justify-content: space-between;
        color: #ddddf7;

        .logo {
          font-size: 19px;
          color: #141414;
          font-weight: 500;
          background-color: transparent;
          padding: 0px 3px;

          @include tablet {
            display: none;
          }
        }



        .user {

          display: flex;
          background-color: #1b1b1b;
          padding-left: 6px;
          padding-top: 4px;
          padding-bottom: 4px;
          border-radius: 20px;
          cursor: pointer;

          img {
            background-color: #ffffff;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 5px;
          }

          .username {
            font-weight: 600;
            margin-right: 17px;
            color: #DFD3C3;
            transition: 0.2s ease-in-out;


          }

        }

      }

      .search {

        border-bottom: 2px solid #D0B8A8;
        background-color: transparent;

        .searchForm {
          padding: 10px;

          input {

            background-color: transparent;
            border: none;
            color: #85586F;
            outline: none;

            &::placeholder {
              color: #3e2733;
            }
          }
        }
      }

      .userChat {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: rgb(33, 33, 33);
        cursor: pointer;
        transition: 0.2s ease-in-out;



        &:hover {
          background-color: #D0B8A8;
          transition: 0.2s ease-in-out;
        }

        img {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          object-fit: cover;
        }

        .userChatInfo {
          span {
            font-size: 18px;
            font-weight: 500;

          }

          p {
            font-size: 14px;
            color: #3e2733;
          }
        }
      }
    }

    .chat {
      flex: 2;

      .chatInfo {
        height: 50px;
        background-color: #2d2d2d;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        color: lightgray;

      }

      .displayName {
        font-size: 17px;
        color: #dcdcdc;
      }

      .chatIcons {
        display: flex;
        gap: 10px;
        margin-right: 10px;


        img {
          height: 24px;
          cursor: pointer;
        }
      }

      .messages {
        background-color: #e1cbbc;
        padding: 10px;
        height: calc(100% - 160px);
        overflow: scroll;


        .message {
          display: flex;
          gap: 20px;
          margin-top: 20px;
          margin-bottom: 20px;


          .messageInfo {
            display: flex;
            flex-direction: column;
            color: gray;
            font-weight: 300;


            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }
          }

          .messageContent {
            max-width: 80%;
            display: flex;
            flex-direction: column;
            gap: 10px;



            p {
              background-color: #D0B8A8;
              padding: 10px 20px;
              border-radius: 0px 10px 10px 10px;
              max-width: max-content;
              font-weight: 400;

            }

            img {
              width: 50%;
            }


          }

          &.owner {
            flex-direction: row-reverse;

            .messageContent {
              align-items: flex-end;


              p {
                background-color: #85586F;
                color: white;
                border-radius: 10px 0px 10px 10px;


              }
            }
          }
        }
      }

      .input {
        height: 50px;
        background-color: #85586F;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        input {
          background-color: transparent;
          width: 100%;
          border: none;
          outline: none;
          color: #F8EDE3;
          font-size: 18px;
          transition: 0.3s ease-in-out;


          &::placeholder {
            color: #F8EDE3;

          }
        }

        input:focus {
          font-size: 20px;
          transition: 0.3s ease-in-out;

        }

        input:hover {
          font-size: 20px;
          transition: 0.3s ease-in-out;

        }


        .send {
          display: flex;
          align-items: center;
          gap: 10px;


          img {
            height: 24px;
            cursor: pointer;
          }

          button {
            border: none;
            padding: 10px 15px;
            color: #F5E8C7;
            background-color: #AC7088;
            padding: 10px 20px;
            border-radius: 20px;
            cursor: pointer;
            transition: 0.2s ease-in-out;

          }

          button:hover {
            background-color: #794e5f;
            transition: 0.2s ease-in-out;
          }
        }
      }
    }
  }
}